import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { ApiRequest } from 'GlobalFunctions';

export default function AutocompleteField(props: any){

  const {
    endpoint = "",
    endpoint_params = {},
    preloadedList,
    label,
    value,
    onChange,
    renderValues = ["label"],
    multiple = false,
  } = props;

  const [list, setList] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>(multiple ? [] : null);

  useEffect(() => {
    if (preloadedList) {
      setList(preloadedList);
    } else {
      getList();
    }
  }, [preloadedList, JSON.stringify(endpoint_params)]);

  const getList = async () => {
    await ApiRequest({
      url: endpoint,
      method: "get",
      headers: {
          ltkn: localStorage.getItem('ltkn')
      },
      query: {
          full_list: true,
          ...endpoint_params
      }, 
      setResponse: (response: any) => {
          setList(response.list);
      },
    });
  }

  useEffect(() => {
    if (!multiple) {
      if (list && (value && value != 0)) { 
        let item = list.find((obj: any) => obj.value === parseInt(value));
        if (item) {
          console.log("item", item);
          setSelectedItems(item);
        }
      }
    } else {
      if (list && value && Array.isArray(value)) {
        let items = list.filter((obj: any) => value.includes(obj.value));
        setSelectedItems(items);
      }
    }
  }, [list, value, multiple]);

  return (
    <>
      {list && list.length > 0 ? (
        <Autocomplete
          value={selectedItems}
          onChange={(event: any, newValue: any) => {
            if (!multiple) {
              // Aquí comprobamos si se eliminó el valor (newValue será null)
              setSelectedItems(newValue);  // Actualiza el estado de selectedItems
              onChange(newValue ? newValue : null);  // Pasa null si se elimina el valor
            }
            else {
              setSelectedItems(newValue);
              onChange(newValue ? newValue.map((item: any) => item.value) : []); // Pasa un array vacío si se elimina el valor
            }
          }}
          multiple={multiple}
          id="autocomplete"
          options={list}
          fullWidth
          getOptionLabel={(option: any) => `${
            renderValues.map((renderValue: any) => (
              option[renderValue]
            )).join(" - ")
          }`}
          renderOption={(props: any, option: any) => (
            <li className="flex_select" {...props} key={option.value}>
              {renderValues.map((renderValue: any, index: any) => (
                <span key={index}>
                  {option[renderValue]}
                  {index < renderValues.length - 1 && " - "}
                </span>
              ))}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
        />
      ) : (
        <>
          <p style={{color:"red"}}>Sin {label}</p>
        </>
      )}
    </>
  );
}
