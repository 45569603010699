import axios from 'axios';
import { useEffect, useState } from 'react';
import { Avatar, Button, CircularProgress, Dialog, IconButton, Modal, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';
import Filters from 'elements/Filters/Filters';
import TuneIcon from '@mui/icons-material/Tune';
import FiltersApplied from 'elements/Filters/FiltersApplied';
import FiltersProducts from 'elements/Filters/FiltersProducts';

import 'assets/css/main.css'; 

import ProductEditor from './ProductsEditor';
import Box from 'elements/Box';
import { ApiRequest } from 'GlobalFunctions';

export default function ProductList(props: any) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    /*=========================================================
  FILTERS
  =========================================================*/

  const [applyFilters, setApplyFilters] = useState({});
  const [filtersValues, setFiltersValues] = useState<any>({});

  const [filters, setFilters] = useState([]);
  /*=========================================================*/


  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, []) 
  
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const currentPage = parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);

  const [openEditor, setOpenEditor] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page, filters]) 

  const loadList = async () => {
    setLoading(true);
    console.log(filters)

    await ApiRequest({
      url: "/products/list",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        page,
        filters,
      },
      setResponse: (response: any) => {
        setList(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      },
    });
    
    localStorage.setItem('page', page.toString())
  }

  const handleDelete = (product_id:number) => {
    setActiveItem(product_id)
    setOpenAlert(true)
  }

  const deleteItem = () => {
    const body = {
      product_id: activeItem
    }

    axios.post(ProjectConfig.api_url+"/products/deleteItem", body)
      .then((response) => {
        console.log(response.data);
        if(response.data.status === "success"){
          enqueueSnackbar('Producto eliminado con éxito', { variant: 'success' });
          loadList();
        }
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  
  const [openAlert, setOpenAlert] = useState(false);
  const [activeItem, setActiveItem] = useState<any>(null);

  
  const [openFilters, setOpenFilters] = useState(false);

  return (
    <div id='UsersList' className='ModuleContainer'>
    <div className="ModuleWrapper"> 
      <div className="boxContainerHeader">
        
        <div className="boxContainerTitle">
          <h2 className="h2_title">Productos</h2>
          <span className="h2_subtitle">Lista de productos y existencias</span>
        </div>
        
        <div className="boxContainerActions">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setActiveItem(null)
            setOpenEditor(true)
          }}
          startIcon={<AddIcon />}
        >
          Crear producto
        </Button>

  
      
        


        </div>
        
      </div>

      <FiltersProducts 
            open={openFilters}
            setOpen={setOpenFilters}
            filters={filters}
            setFilters={setFilters}
            setApplyFilters={setApplyFilters}
          />

      <div id='UsersListIn'>
        <Box id='ProductList' className='box100'>
          <TableContainer>
            <Table aria-label="product table">
              <TableHead>
                <TableRow>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>NOMBRE / COD</TableCell>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>PRECIOS</TableCell>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>EXISTENCIAS</TableCell>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>EMPAQUE</TableCell>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>CONVERSIÓN</TableCell>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>UNIDAD DE MEDIDA</TableCell>
                  <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>ACCIONES</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item, i) => (
                  <TableRow key={i} hover role="checkbox">

                    <TableCell>
                      <span className='flex_title'>{item.product_name}</span>
                      <span className='flex_subtitle'>{item.product_code}</span>
                    </TableCell>
                    
                    <TableCell>
                        {item.price_1 != 0 && <span className='price_text'>Precio 1: ${item.price_1.toLocaleString()}</span>}
                        {item.price_2 != 0 && <span className='price_text'>Precio 2: ${item.price_2.toLocaleString()}</span>}
                        {item.price_3 != 0 && <span className='flex_subtitle'>Precio 3: ${item.price_3.toLocaleString()}</span>}
                    </TableCell>
                    <TableCell>{item.stock}</TableCell>
                    <TableCell>{item.product_package}</TableCell>
                    <TableCell>{item.product_conversion}</TableCell>
                    <TableCell>{item.product_measure_type}</TableCell>
                    <TableCell align="right">
                      <Button 
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{"marginRight": "10px"}}
                        startIcon={<ModeEditIcon />}
                        onClick={() => {
                          setActiveItem(item.product_id)
                          setOpenEditor(true)
                        }}
                      >
                        Editar
                      </Button>
                      {localStorage.getItem('role_id') === "1" || localStorage.getItem('role_id') === "2" &&
                      <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(item.product_id)}>
                          <DeleteOutlineOutlinedIcon />
                      </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination count={total_pages} page={page} onChange={(event, value) => setPage(value)} color="primary" style={{"marginTop": "20px","alignSelf": "center"}} />
        </Box>

      </div>
    </div>
                  
        <ProductEditor
          openEditor={openEditor}
          setOpenEditor={setOpenEditor}
          loadList={loadList}
          product_id={activeItem}
          setLoading={setLoading}
        />

      </div>
  );
}
